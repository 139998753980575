var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('overlay',{attrs:{"show":_vm.loading,"class-name":"auth-wrapper auth-v2"}},[_c('b-row',{staticClass:"auth-inner m-0 justify-content-center",style:(_vm.loginStyle)},[_c('b-col',{staticClass:"pt-5",attrs:{"lg":"10"}},[_c('InitialHeader',{attrs:{"only-logo":true}})],1),_c('b-col',{staticClass:"d-flex align-items-center mb-5",attrs:{"sm":"12","lg":"12"}},[_c('b-col',{staticClass:"px-lg-3 mx-auto auth-bg py-5 rounded",attrs:{"sm":"12","md":"10","lg":"4"}},[_c('h2',{staticClass:"mb-1 auth-title"},[_vm._v(" Faça parte do "),_c('span',{staticClass:"lt-p1 titles-color"},[_vm._v("vacina")]),_c('span',{staticClass:"lt-p2 titles-color"},[_vm._v("sesi")]),_c('br'),_vm._v(" para empresas! ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Por favor, insira as informações abaixo: ")]),(_vm.alert.show)?_c('b-alert',{staticClass:"mb-2",attrs:{"show":"","fade":"","variant":_vm.alert.variant}},[_c('div',{staticClass:"custom-alert"},[_c('span',[_c('BIconExclamationCircle'),_vm._v(" "+_vm._s(_vm.alert.message)+" ")],1)])]):_vm._e(),_c('validation-observer',{ref:"formItems"},[_c('b-form',{staticClass:"auth-login-form mt-2"},[_c('b-form-group',{attrs:{"label":"Nome Completo*","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Nome Completo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","autocomplete":"off","placeholder":"João da Silva"},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"E-mail*","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"E-mail","vid":"E-mail","rules":"required|email:@confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","autocomplete":"off","placeholder":"email@exemplo.com"},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Confirmação de E-mail*","label-for":"confirmEmail"}},[_c('validation-provider',{attrs:{"name":"Confirmação de E-mail","rules":("required|email|confirmedEmail:" + (_vm.formData.email))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"confirmEmail","name":"confirm","autocomplete":"off","placeholder":"email@exemplo.com"},on:{"paste":function($event){$event.preventDefault();return (function () {
                      return false;
                    }).apply(null, arguments)}},model:{value:(_vm.formData.emailConfirmation),callback:function ($$v) {_vm.$set(_vm.formData, "emailConfirmation", $$v)},expression:"formData.emailConfirmation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"CPF*","label-for":"cpf"}},[_c('validation-provider',{attrs:{"name":"CPF","rules":"required|cpf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],attrs:{"id":"cpf","autocomplete":"off","placeholder":"000.000.000-00"},model:{value:(_vm.formData.cpf),callback:function ($$v) {_vm.$set(_vm.formData, "cpf", $$v)},expression:"formData.cpf"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Sexo*","label-for":"sex"}},[_c('validation-provider',{attrs:{"name":"Sexo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"status","options":_vm.sexesOptions,"variant":"custom","item-text":"description","item-value":"value","placeholder":"Selecione o sexo","label":"description"},model:{value:(_vm.formData.sex),callback:function ($$v) {_vm.$set(_vm.formData, "sex", $$v)},expression:"formData.sex"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Data de Nascimento*","label-for":"nascimento"}},[_c('validation-provider',{attrs:{"name":"Data de Nascimento","rules":"required|nascimento"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"id":"nascimento","autocomplete":"off","placeholder":"00/00/0000"},model:{value:(_vm.formData.nascimento),callback:function ($$v) {_vm.$set(_vm.formData, "nascimento", $$v)},expression:"formData.nascimento"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nome da mãe","label-for":"nomeMae"}},[_c('validation-provider',{attrs:{"name":"nomeMae"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nomeMae","autocomplete":"off","placeholder":"Nome da mãe"},model:{value:(_vm.formData.nomeMae),callback:function ($$v) {_vm.$set(_vm.formData, "nomeMae", $$v)},expression:"formData.nomeMae"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"form-group"},[_c('NacionalidadeField',{attrs:{"idNacionalidade":_vm.formData.idNacionalidade,"customClass":"register"},on:{"updateNacionalidade":_vm.updateNacionalidade}})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"remember-me","name":"checkbox-1"},on:{"change":function($event){_vm.alert.show = false}},model:{value:(_vm.formData.agree),callback:function ($$v) {_vm.$set(_vm.formData, "agree", $$v)},expression:"formData.agree"}},[_vm._v(" Eu concordo com o termo de "),_c('b-link',{attrs:{"to":{ name: 'politica-privacidade' },"target":"_blank"}},[_vm._v(" Política de Privacidade ")]),_vm._v(" do VacinaSESI para Empresas. ")],1)],1),_c('button',{staticClass:"btn button-form btn-block",attrs:{"type":"button"},on:{"click":_vm.formSubmit}},[_vm._v(" Registrar ")])],1)],1),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Já possui uma conta?")]),_c('b-link',{attrs:{"to":{ name: 'auth-login' }}},[_c('span',[_vm._v(" Faça o Login")])])],1)],1)],1),_c('FooterInitial')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }