<template>
  <overlay
    :show="loading"
    class-name="auth-wrapper auth-v2"
  >
    <b-row
      :style="loginStyle"
      class="auth-inner m-0 justify-content-center"
    >
      <b-col
        lg="10"
        class="pt-5"
      >
        <InitialHeader :only-logo="true" />
      </b-col>
      <b-col
        sm="12"
        lg="12"
        class="d-flex align-items-center mb-5"
      >
        <b-col
          sm="12"
          md="10"
          lg="4"
          class="px-lg-3 mx-auto auth-bg py-5 rounded"
        >
          <h2 class="mb-1 auth-title">
            Faça parte do
            <span class="lt-p1 titles-color">vacina</span>
            <span class="lt-p2 titles-color">sesi</span>
            <br>
            para empresas!
          </h2>

          <b-card-text class="mb-2">
            Por favor, insira as informações abaixo:
          </b-card-text>

          <b-alert
            v-if="alert.show"
            show
            fade
            class="mb-2"
            :variant="alert.variant"
          >
            <div class="custom-alert">
              <span> <BIconExclamationCircle /> {{ alert.message }} </span>
            </div>
          </b-alert>

          <validation-observer ref="formItems">
            <b-form
              class="auth-login-form mt-2"
            >
              <b-form-group
                label="Nome Completo*"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nome Completo"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="formData.name"
                    autocomplete="off"
                    placeholder="João da Silva"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="E-mail*"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="E-mail"
                  vid="E-mail"
                  rules="required|email:@confirm"
                >
                  <b-form-input
                    id="email"
                    v-model="formData.email"
                    autocomplete="off"
                    placeholder="email@exemplo.com"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="Confirmação de E-mail*"
                label-for="confirmEmail"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirmação de E-mail"
                  :rules="`required|email|confirmedEmail:${formData.email}`"
                >
                  <b-form-input
                    id="confirmEmail"
                    v-model="formData.emailConfirmation"
                    name="confirm"
                    autocomplete="off"
                    placeholder="email@exemplo.com"
                    @paste.prevent="
                      () => {
                        return false;
                      }
                    "
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="CPF*"
                label-for="cpf"
              >
                <validation-provider
                  #default="{ errors }"
                  name="CPF"
                  rules="required|cpf"
                >
                  <b-form-input
                    id="cpf"
                    v-model="formData.cpf"
                    v-mask="'###.###.###-##'"
                    autocomplete="off"
                    placeholder="000.000.000-00"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="Sexo*"
                label-for="sex"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Sexo"
                  rules="required"
                >
                  <v-select
                    id="status"
                    v-model="formData.sex"
                    :options="sexesOptions"
                    variant="custom"
                    item-text="description"
                    item-value="value"
                    placeholder="Selecione o sexo"
                    label="description"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="Data de Nascimento*"
                label-for="nascimento"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Data de Nascimento"
                  rules="required|nascimento"
                >
                  <b-form-input
                    id="nascimento"
                    v-model="formData.nascimento"
                    v-mask="'##/##/####'"
                    autocomplete="off"
                    placeholder="00/00/0000"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Nome da mãe" label-for="nomeMae">
                <validation-provider #default="{ errors }" name="nomeMae">
                  <b-form-input
                    id="nomeMae"
                    autocomplete="off"
                    placeholder="Nome da mãe"
                    v-model="formData.nomeMae"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <div class="form-group">
                <NacionalidadeField
                  :idNacionalidade="formData.idNacionalidade"
                  @updateNacionalidade="updateNacionalidade"
                  customClass="register"
                />
              </div>

              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="formData.agree"
                  name="checkbox-1"
                  @change="alert.show = false"
                >
                  Eu concordo com o termo de
                  <b-link
                    :to="{ name: 'politica-privacidade' }"
                    target="_blank"
                  >
                    Política de Privacidade
                  </b-link>
                  do VacinaSESI para Empresas.
                </b-form-checkbox>
              </b-form-group>

              <button
                type="button"
                class="btn button-form btn-block"
                @click="formSubmit"
              >
                Registrar
              </button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Já possui uma conta?</span>
            <b-link :to="{ name: 'auth-login' }">
              <span>&nbsp;Faça o Login</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
      <FooterInitial />
    </b-row>
  </overlay>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BIconExclamationCircle,
  BFormCheckbox,
  BCardText,
  BForm,
  BAlert,
  VBTooltip,
} from 'bootstrap-vue'

import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  required,
  cpf,
  email,
  confirmedEmail,
} from '@validations'
import vSelect from 'vue-select'
import { strClear } from '@core/utils/utils'
import moment from 'moment/moment'
import FooterInitial from '@/views/components/custom/footer/FooterInitial.vue'
import InitialHeader from '@/views/components/custom/page-header/InitialHeader.vue'
import Overlay from '@/views/components/custom/overlay/Overlay.vue'
import { createUser } from '@/views/pages/authentication/requests'
import { warningInfoMessage, warningMessage } from '@/libs/sweetalerts'
import NacionalidadeField from "@/views/components/custom/field-select-nacionalidade/NacionalidadeField.vue";

export default {
  title: 'Cadastro',

  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    Overlay,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BIconExclamationCircle,
    BFormCheckbox,
    BCardText,
    BForm,
    BAlert,
    FooterInitial,
    InitialHeader,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    NacionalidadeField,
  },
  mounted() {
    this.$helpers.indexablePage();
  },

  data() {
    return {
      required,
      cpf,
      email,
      confirmedEmail,

      loading: false,

      formData: {
        name: '',
        email: '',
        cpf: '',
        sex: null,
        nascimento: '',
        agree: false,
        emailConfirmation: '',
        nomeMae: "",
        idNacionalidade: null,
      },

      sexesOptions: [
        {
          value: 'Masculino',
          description: 'Masculino',
        },
        {
          value: 'Feminino',
          description: 'Feminino',
        },
      ],

      loginStyle: {
        'background-image': `url(${require('@/assets/custom-images/bg/bg-login.png')})`,
        'background-repeat': 'no-repeat',
        'background-position': 'center center',
        'background-size': 'cover',
      },

      alert: {
        show: false,
        message: '',
        variant: '',
      },
    }
  },

  computed: {
    isMatching() {
      return this.newRegister.email != this.newRegister.confirmEmail &&
        this.newRegister.confirmEmail.length
        ? "O e-mail de confirmação não corresponde com o e-mail informado. Por favor, verifique."
        : "";
    },
  },

  methods: {
    async formSubmit() {
      const resultFormItems = new Promise((resolve, reject) => {
        this.$refs.formItems
          .validate()
          .then(success => {
            if (success) {
              resolve(true)
            }
          })
          .catch(() => {
            reject()
          })
      })

      if (await resultFormItems) {
        await this.handleCreate()
      }
    },

    async handleCreate() {
      if (!this.formData.agree) {
        this.showAlert(
          'Você deve concordar com o termo de Política de Privacidade do VacinaSESI para continuar.',
          'warning',
        )

        return
      }

      this.loading = true

      const formData = {
        nome: this.formData.name,
        email: this.formData.email,
        confirmacaoEmail: this.formData.emailConfirmation,
        cpf: strClear(this.formData.cpf),
        sexo: this.formData.sex.value,
        dataNascimento: moment(
          this.formData.nascimento,
          'DD-MM-YYYY',
        ).format('YYYY-MM-DD'),
        nomeMae: this.formData.nomeMae,
        idNacionalidade: this.formData.idNacionalidade,
      }

      await createUser(formData)
        .then(response => {
          if (response.status === 201) {
            const { data } = response

            this.$router.push({
              name: 'email-sent',
              params: {
                email: this.formData.email,
                idUsuario: data.id_usuario,
              },
            })
          }
        })
        .catch(error => {
          this.handleError(error.response)
        })

      this.loading = false
    },

    handleError(response) {
      const errors = response.status === 400 || response.status === 404

      if (errors) {
        warningInfoMessage(
          response.data.errors,
          'Confira os dados e depois salve o usuário.',
          'Entendido',
        )

        return
      }

      warningMessage(
        'Não foi possível realizar a sua solicitação.<br /> Entre em contato com o suporte.',
      )
    },

    showAlert(message, variant) {
      this.alert.show = true;
      this.alert.message = message;
      this.alert.variant = variant;
    },

    clear() {
      this.formData = {
        name: '',
        email: '',
        cpf: '',
        sex: null,
        nascimento: '',
        agree: false,
        emailConfirmation: '',
        nomeMae: '',
        idNacionalidade: null,
      }
    },

    updateNacionalidade(idNacionalidade) {
      this.formData.idNacionalidade = idNacionalidade;
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
