import axiosIns from '@/libs/axios'
import rotasAPI from '@/router/rotasAPI'

export const createUser = form => new Promise((resolve, reject) => {
  axiosIns
    .post(rotasAPI.usuarioVacinasParaEmpresas(), form)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})
